.addVisibleBonds {
  height: 54px;
  text-decoration: blink;
  animation-name: blinkMe1;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}
@keyframes blinkMe1 {
  0% {
    border-radius: "4px";
    border: 1px solid #54a9ff;
    background-color: #54a9ff40;
  }
  50% {
    border-radius: "4px";
    border: 1px solid #54a9ff;
    background: #54a9ff40;
  }
  100% {
    border-radius: "4px";
    border: none;
    border: 1px solid transparent;
    background: none;
  }
}
.tradedBonds {
  text-decoration: blink;
  animation-name: blinkMe2;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}
@keyframes blinkMe2 {
  0% {
    background-color: #5cb85c;
  }
  50% {
    background: #5cb85c;
  }
  100% {
    background: #24414b;
  }
}
.blurredCell {
  filter: blur(5px); /* Adjust the blur amount as needed */
}
.customTooltip::-webkit-scrollbar {
  width: 12px;
}

.customTooltip::-webkit-scrollbar-thumb {
  background-color: #4caf50;
  border-radius: 6px;
}

.customTooltip::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 6px;
}
