.loader {
  position: relative;
  width: 160px;
  height: 160px;
  border: 4px solid #282828;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: -5px -5px 5px rgba(255, 255, 255, 0.1),
    10px 10px 10px rgba(0, 0, 0, 0.4),
    inset -5px -5px 5px rgba(255, 255, 255, 0.2),
    inset 10px 10px 10px rgba(0, 0, 0, 0.4);
  margin : auto 
}

.loader:before {
  content: "";
  position: absolute;
  top: 25px;
  left: 25px;
  right: 25px;
  bottom: 25px;
  z-index: 10;
  background: #212121;
  border-radius: 50%;
  border: 2px solid #292929;
  box-shadow: inset -2px -2px 5px rgba(255, 255, 255, 0.2),
    inset 3px 3px 5px rgba(0, 0, 0, 0.5);
}

.loader span {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: linear-gradient(
    -225deg,
    #2666ff 0%,
    #2692ff 50%,
    #54a9ff 100%
  );
  filter: blur(20px);
  animation: animate 0.5s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
