.ag-theme-quartz, .ag-theme-quartz-dark {
    --ag-header-foreground-color:#757882; 
    --ag-background-color: #2d2d2d 
     --ag-font-size: 2px;
    --ag-font-family: inter;
    --ag-icon-font-color:#757882; 
}
.ag-theme-quartz .ag-menu {
    background-color: #2D2D2D;
    border-radius: 4px;
    opacity: 1;
    box-shadow: 0px 6px 8px #000000A1;
}

.ag-header-container .ag-header-cell-menu-button{
    visibility: visible !important;    
    opacity:1 !important;
   
}
.ag-menu input {
    color: white !important;
}

.ag-select:not(.ag-cell-editor, .ag-label-align-top) {
    display: none !important;
}
.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within{
    border-color: transparent !important;
}
span.ag-overlay-no-rows-center {
    color: #757882;
}

