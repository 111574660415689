.App {
  text-align: center;

}

input[type="date"]::-webkit-calendar-picker-indicator {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%235aabff' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z'/%3E%3C/svg%3E");
  background-size: 15px 15px;
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
  display: inline-block;
  margin-right: 10px; /* You ca
  n adjust the margin as needed */
  margin-right : 10px;
  margin-bottom : 5px
}

.MuiToolbar-root.MuiToolbar-regular.tool-bar-container.MuiToolbar-gutters {
  min-width: 55px;
}

.Toastify__toast-theme--dark {
  background-color: red;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 16px;
}

/* background of the scrollbar */

::-webkit-scrollbar-track {
  background: #222222;
  border-radius: 8px;
  width: 12px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background-color: #3a3c3f;
  border-radius: 16px;
  border: 4px solid #222222;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-corner {
/*
background-image: url(resources/corner.png);
background-repeat: no-repeat;
*/
background-color: inherit;
}