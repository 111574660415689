.tradedLogs {
    text-decoration: blink;
    animation-name: blinkMe3;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    font-size: "13px !important";
    color: "#ffffff !important";
    text-align: left;
    border-bottom: "none";
    white-space: "nowrap";
    overflow: "hidden";
    text-overflow: "ellipsis";
  }
  @keyframes blinkMe3 {
    0% {
      background-color: #FF9100;
    }
    50% {
      background: #FF9100;
    }
    100% {
      background: #24414b;
    }
  }